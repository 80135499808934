button {
    transition: transform 0.2s ease;
}

button:hover {
    transform: scale(1.15);
}

button:active {
    transform: scale(0.9);
}